<template>  
 <ContentHeader :first_name="first_name" :last_name="last_name" :avatar="avatar" :id="user_id"/>
  <div id="content-page" class="content-page">
  <div class="container">
    <div class="row">   
        <SocialPoster :avatar="avatar"/>
        <input type="hidden" name="page_id" id="page_id" value="0"/>

    </div>
    <div class="row">
        <div class="col-lg-12">            
        </div>
    </div>
    <div class="row">   
        <div class="col-lg-3 d-none d-sm-block">      
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Stories</h4>
                    </div>
                </div>
                <div class="card-body">
                    <ul class="media-story list-inline m-0 p-0">
                        <li class="d-flex mb-3 align-items-center">
                            <i class="ri-add-line"></i>
                            <div class="stories-data ms-3">
                                <h5>Creat Your Story</h5>
                                <p class="mb-0">time to story</p>
                            </div>
                        </li>
                        <li class="d-flex mb-3 align-items-center active">
                            <img src="https://tshaku.com/view/socialV/assets/images/page-img/s2.jpg" alt="story-img" class="rounded-circle img-fluid">
                            <div class="stories-data ms-3">
                                <h5>Anna Mull</h5>
                                <p class="mb-0">1 hour ago</p>
                            </div>
                        </li>
                        <li class="d-flex mb-3 align-items-center">
                            <img src="https://tshaku.com/view/socialV/assets/images/page-img/s3.jpg" alt="story-img" class="rounded-circle img-fluid">
                            <div class="stories-data ms-3">
                                <h5>Ira Membrit</h5>
                                <p class="mb-0">4 hour ago</p>
                            </div>
                        </li>
                        <li class="d-flex align-items-center">
                            <img src="https://tshaku.com/view/socialV/assets/images/page-img/s1.jpg" alt="story-img" class="rounded-circle img-fluid">
                            <div class="stories-data ms-3">
                                <h5>Bob Frapples</h5>
                                <p class="mb-0">9 hour ago</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Events</h4>
                    </div>
                    <div class="card-header-toolbar d-flex align-items-center">
                        <div class="dropdown">
                            <div class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                <i class="ri-more-fill h4"></i>
                            </div>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" style="">
                                <a class="dropdown-item" href="#"><i class="ri-eye-fill me-2"></i>View</a>
                                <a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill me-2"></i>Delete</a>
                                <a class="dropdown-item" href="#"><i class="ri-pencil-fill me-2"></i>Edit</a>
                                <a class="dropdown-item" href="#"><i class="ri-printer-fill me-2"></i>Print</a>
                                <a class="dropdown-item" href="#"><i class="ri-file-download-fill me-2"></i>Download</a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="media-story list-inline m-0 p-0">
                            <li class="d-flex mb-4 align-items-center ">
                                <img src="https://tshaku.com/view/socialV/assets/images/page-img/s4.jpg" alt="story-img" class="rounded-circle img-fluid">
                                <div class="stories-data ms-3">
                                    <h5>Web Workshop</h5>
                                    <p class="mb-0">1 hour ago</p>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <img src="https://tshaku.com/view/socialV/assets/images/page-img/s5.jpg" alt="story-img" class="rounded-circle img-fluid">
                                <div class="stories-data ms-3">
                                    <h5>Fun Events and Festivals</h5>
                                    <p class="mb-0">1 hour ago</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                            <h4 class="card-title">Upcoming Birthday</h4>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="media-story list-inline m-0 p-0">
                            <li class="d-flex mb-4 align-items-center">
                                <img src="https://tshaku.com/view/socialV/assets/images/user/01.jpg" alt="story-img" class="rounded-circle img-fluid">
                                <div class="stories-data ms-3">
                                    <h5>Anna Sthesia</h5>
                                    <p class="mb-0">Today</p>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <img src="https://tshaku.com/view/socialV/assets/images/user/02.jpg" alt="story-img" class="rounded-circle img-fluid">
                                <div class="stories-data ms-3">
                                    <h5>Paul Molive</h5>
                                    <p class="mb-0">Tomorrow</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header d-flex justify-content-between">
                        <div class="header-title">
                            <h4 class="card-title">Suggested Pages</h4>
                        </div>
                        <div class="card-header-toolbar d-flex align-items-center">
                            <div class="dropdown">
                                <div class="dropdown-toggle" id="dropdownMenuButton01" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                                    <i class="ri-more-fill h4"></i>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton01">
                                <a class="dropdown-item" href="#"><i class="ri-eye-fill me-2"></i>View</a>
                                <a class="dropdown-item" href="#"><i class="ri-delete-bin-6-fill me-2"></i>Delete</a>
                                <a class="dropdown-item" href="#"><i class="ri-pencil-fill me-2"></i>Edit</a>
                                <a class="dropdown-item" href="#"><i class="ri-printer-fill me-2"></i>Print</a>
                                <a class="dropdown-item" href="#"><i class="ri-file-download-fill me-2"></i>Download</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <ul class="suggested-page-story m-0 p-0 list-inline">
                        <li class="mb-3">
                            <div class="d-flex align-items-center mb-3">
                                <img src="https://tshaku.com/view/socialV/assets/images/page-img/42.png" alt="story-img" class="rounded-circle img-fluid avatar-50">
                                <div class="stories-data ms-3">
                                    <h5>Iqonic Studio</h5>
                                    <p class="mb-0">Lorem Ipsum</p>
                                </div>
                            </div>
                            <img src="https://tshaku.com/view/socialV/assets/images/small/img-1.jpg" class="img-fluid rounded" alt="Responsive image">
                            <div class="mt-3"><a href="#" class="btn d-block"><i class="ri-thumb-up-line me-2"></i> Like Page</a></div>
                        </li>
                        <li class="">
                            <div class="d-flex align-items-center mb-3">
                                <img src="https://tshaku.com/view/socialV/assets/images/page-img/42.png" alt="story-img" class="rounded-circle img-fluid avatar-50">
                                <div class="stories-data ms-3">
                                    <h5>Cakes & Bakes </h5>
                                    <p class="mb-0">Lorem Ipsum</p>
                                </div>
                            </div>
                            <img src="https://tshaku.com/view/socialV/assets/images/small/img-2.jpg" class="img-fluid rounded" alt="Responsive image">
                            <div class="mt-3"><a href="#" class="btn d-block"><i class="ri-thumb-up-line me-2"></i> Like Page</a></div>
                        </li>
                    </ul>
                </div>
            </div>      
        </div>   
        <div class="col-lg-9 row m-0 p-0">
            <div class="col-sm-12">         
                <!-- START USER POSTS -->                     
                <div id="all-wall-posts">                        
                    <!-- END USER POSTS -->     
                    <div v-for="post in posts" :key="post.post_id">
                        <Posts :seen_posts="seen_posts" :post_comments="post.post_comments" :post_date="post.post_date" :post_user_sub="post.user_sub" :post_text="post.post_text" :post_shares="post.post_shares" :post_link="post.post_link" :post_likes="post.post_likes" :post_page_id="post.post_page_id" :post_image="post.post_image" :post_by_avatar="post.post_by_avatar" :post_by_id="post.post_by_id" :post_by_names="post.post_by_names" :post_id="post.post_id"  :post_type="post.post_type" /> 
                    </div>       
                </div>  
                <input type="hidden" id="active_post" value="0"/>
                <div id="spin_loader"><img src="https://tshaku.com/view/socialV/assets/images/page-img/page-load-loader.gif"/></div>         
                    <router-link :to="'/'" class="btn btn-primary d-block w-100 mt-3">Load More Posts</router-link>
                    <input type="hidden" id="last_loaded_post" value="0"/>
                    <br/>                               
                </div> 
            </div>
            <div class="col-sm-12 text-center">                            
            </div>
        </div>
    </div>
  </div>
  <ContentFooter/>
</template>

<script>
import router from '@/router'
import axios from 'axios'

import ContentHeader from './includes/ContentHeader.vue'
import ContentFooter from './includes/ContentFooter.vue'

import SocialPoster from './includes/SocialPoster.vue'
import Posts from './includes/Post.vue'

export default {
    name: 'Home',
    data(){
        return{
            posts:'',                        
            seen_posts:'',
            posts_loaded:false           
        }        
    },
    computed:{
        
    },
    props:['user_id','first_name','last_name','avatar'],
    mounted(){ 
        
    },
    components:{SocialPoster,Posts,ContentHeader,ContentFooter},    
    created(){ 
        this.load_posts()
        //*/
    },
    watch: { 
        seen_posts(){
            
        }
    },    
    methods:{        
        check_posts(){        
            $(window).scroll(function() {             
            const posts=JSON.parse(localStorage.getItem('Home_posts'))
            if(posts){                                           
                for (var k in posts){                    
                    var post=posts[k]                                        
                    var the_div='post_is_'+post.post_id                                                            
                    var element = document.getElementById(the_div)
                    if(element){
                        const elementTop = element.offsetTop;             
                        const elementBottom = elementTop + element.offsetHeight; 
                        const viewportTop = window.pageYOffset; 
                        const viewportBottom = viewportTop + window.innerHeight;   
                        if (elementBottom > viewportTop && elementTop < viewportBottom) {
                            //Check if the seen post is already in the array
                            if(!this.seen_posts){
                                //The seen posts variable is empty
                                this.seen_posts+='|'+post.post_id
                            }
                            else{
                                //The seen posts variable is not empty
                                //Check if this post was already added to it
                                var seen_posts=this.seen_posts;
                                var myarr = seen_posts.split('|');
                                if(!myarr.includes(post.post_id)){
                                    //This is not yet recorded
                                    if(post.post_id!='undefined'){
                                        this.seen_posts+='|'+post.post_id                                           
                                        localStorage.setItem('Seen_posts', this.seen_posts);                                        
                                    }                                    
                                }
                            }
                            //console.log(this.seen_posts)
                            
                        } 
                        else {
                            //else what?;
                        }                                   
                    }                    
                }
                //*/
                //Check if any of the loaded posts is in the view port right now
            } 
            //*/           
            });        
        },
        async load_posts(){            
            //*
            //const cachedData = JSON.parse(localStorage.getItem('Home_posts'));
            if(0){
                this.posts=cachedData                
            }
            else{
                const posts = await axios.get('post.php?action=load',{
                                headers: {
                                    Authorization:localStorage.getItem('token')
                                }
                            })                   
                localStorage.setItem('Home_posts', JSON.stringify(posts.data));
                this.posts=posts.data    
                this.posts_loaded=true    
                //Watch for seen posts              
                setTimeout(this.check_posts(),50)
                //End Watch for seen posts
                //console.log(posts.data)
            }            
        },
        visibilityChanged () {     
            //alert('Post id Is visible')
        }
    }   
}
</script>

<style>
    
</style>