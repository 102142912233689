<template>  
    <input type="hidden" name="seen_posts" :id="'postz_'+post_id"/>
    <div class="col-sm-12" :id="'post_is_'+post_id">
        <div class="card card-block card-stretch card-height">
            <div class="card-body" style="padding:0;">
                <div class="user-post-data">
                    <div class="d-flex justify-content-between">
                        <div class="me-3">
                            <a :href="profile_link">
                                <img class="rounded-circle img-fluid" :src="'https://tshaku.com/'+post_by_avatar" alt=""  width="50">
                            </a>
                        </div>
                        <div class="w-100">
                            <div class="d-flex justify-content-between">
                                <div class="">
                                    <h5 class="mb-0 d-inline-block">
                                        <router-link :to="'profile/'+post_by_id">{{ post_by_names}}</router-link>
                                    </h5>
                                    <span class="mb-0 d-inline-block">
                                        <!--Add New Post-->
                                    </span>
                                    <p class="mb-0 text-primary">{{convert_date(post_date)}}</p>
                                </div>
                                <div class="card-post-toolbar">
                                    <div class="dropdown">
                                        <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                            <i class="ri-more-fill"></i>
                                        </span>
                                        <div class="dropdown-menu m-0 p-0">
                                            <a class="dropdown-item p-3" @click="repost()">
                                                <div class="d-flex align-items-top">
                                                    <div class="h4">
                                                        <i class="ri-save-line"></i>
                                                    </div>
                                                    <div class="data ms-2">
                                                        <h6></h6>
                                                        <p class="mb-0">Kasuku</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a class="dropdown-item p-3" href="#">
                                                <div class="d-flex align-items-top">
                                                    <i class="ri-close-circle-line h4"></i>
                                                    <div class="data ms-2">
                                                        <h6>Hide Post</h6>
                                                        <p class="mb-0">See fewer posts like this.</p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a class="dropdown-item p-3" @click="toogleFollow()">
                                                <div class="d-flex align-items-top">
                                                    <i class="ri-user-unfollow-line h4"></i>
                                                    <div class="data ms-2">
                                                        <h6 v-if="isSubed()">unfollow</h6>
                                                        <h6 v-else>follow</h6>
                                                    </div>
                                                </div>
                                            </a>
                                            <a class="dropdown-item p-3" href="#">
                                                <div class="d-flex align-items-top">
                                                    <i class="ri-notification-line h4"></i>
                                                    <div class="data ms-2">
                                                        <h6>Notifications</h6>
                                                        <p class="mb-0">Turn on notifications for this post</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <p :id="'post_text_'+post_id"><a :href="'#postz_'+post_id" @click="load_feed_link(post_link,post_id)">{{post_link}}</a> <span style="color:#fff;" v-html="post_text"></span></p>
                </div>
                <div v-if="post_image && post_type=='video'" class="user-post">
                    <!--<div class=" d-grid grid-rows-2 grid-flow-col gap-3">-->
                    <div style="height:100%;">
                        <div class="row post_zone_div" :id="'post_zone_id_'+post_id" style="height:100%;overflow:hidden;">
                            <a @click="load_wall_video(post_link,post_id)">
                                <img :src="post_image" alt="post-image" class="img-fluid rounded w-100">
                            </a>
                        </div>
                        <!--<div class="row-span-1"><img src="view/socialV/assets/images/page-img/p1.jpg" alt="post-image" class="img-fluid rounded w-100"></div><div class="row-span-1 "><img src="view/socialV/assets/images/page-img/p3.jpg" alt="post-image" class="img-fluid rounded w-100"></div>-->
                    </div>
                </div>
                <div v-else-if="post_image && post_type!='video'" class="user-post">
                    <!--<div class=" d-grid grid-rows-2 grid-flow-col gap-3">-->
                    <div style="height:100%;">
                        <div class="row post_zone_div" :id="'post_zone_id_'+post_id" style="height:100%;overflow:hidden;overflow-y:scroll;">
                            <a onclick="" href="">
                                <img :src="post_image" alt="post-image" class="img-fluid rounded w-100">
                            </a>
                        </div>
                        <!-- <div class="row-span-1"><img src="view/socialV/assets/images/page-img/p1.jpg" alt="post-image" class="img-fluid rounded w-100"></div><div class="row-span-1 "><img src="view/socialV/assets/images/page-img/p3.jpg" alt="post-image" class="img-fluid rounded w-100"></div>-->
                    </div>
                </div>
                <div v-else class="user-post">
                    <div>
                        <div class="row" :id="'post_zone_id_'+post_id" style="height:100%;overflow-x:hidden;overflow-y:scroll;"></div>
                    </div>
                </div>
                <div class="comment-area mt-3">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div class="like-block position-relative d-flex align-items-center">
                            <div class="d-flex align-items-center">
                                <div class="like-data">
                                    <div class="dropdown">
                                        <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                                            <img src="view/socialV/assets/images/icon/01.png" class="img-fluid" alt="">
                                        </span>
                                        <div class="dropdown-menu py-2">
                                            <a class="ms-2 me-2" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Like">
                                                <img src="view/socialV/assets/images/icon/01.png" class="img-fluid" alt="">
                                            </a>
                                            <a class="me-2" href="#"  data-bs-toggle="tooltip" data-bs-placement="top" title="Love">
                                                <img src="view/socialV/assets/images/icon/02.png" class="img-fluid" alt="">
                                            </a>
                                            <a class="me-2" href="#"  data-bs-toggle="tooltip" data-bs-placement="top" title="Happy">
                                                <img src="view/socialV/assets/images/icon/03.png" class="img-fluid" alt="">
                                            </a>
                                            <a class="me-2" href="#"  data-bs-toggle="tooltip" data-bs-placement="top" title="HaHa">
                                                <img src="view/socialV/assets/images/icon/04.png" class="img-fluid" alt="">
                                            </a>
                                            <a class="me-2" href="#"  data-bs-toggle="tooltip" data-bs-placement="top" title="Think">
                                                <img src="view/socialV/assets/images/icon/05.png" class="img-fluid" alt="">
                                            </a>
                                            <a class="me-2" href="#"  data-bs-toggle="tooltip" data-bs-placement="top" title="Sade" >
                                                <img src="view/socialV/assets/images/icon/06.png" class="img-fluid" alt="">
                                            </a>
                                            <a class="me-2" href="#"  data-bs-toggle="tooltip" data-bs-placement="top" title="Lovely">
                                                <img src="view/socialV/assets/images/icon/07.png" class="img-fluid" alt="">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="total-like-block ms-2 me-3">
                                    <div class="dropdown">
                                        <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">{{post_likes}} Likes</span>
                                        <div class="dropdown-menu">
                                            <!--<a class="dropdown-item" href="#">Max Emum</a><a class="dropdown-item" href="#">Bill Yerds</a><a class="dropdown-item" href="#">Hap E. Birthday</a><a class="dropdown-item" href="#">Tara Misu</a><a class="dropdown-item" href="#">Midge Itz</a><a class="dropdown-item" href="#">Sal Vidge</a><a class="dropdown-item" href="#">Other</a>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="total-comment-block">
                                <div class="dropdown">
                                    <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">{{post_comments}} Comments</span>
                                    <div class="dropdown-menu">
                                        <!--<a class="dropdown-item" href="#">Max Emum</a><a class="dropdown-item" href="#">Bill Yerds</a><a class="dropdown-item" href="#">Hap E. Birthday</a><a class="dropdown-item" href="#">Tara Misu</a><a class="dropdown-item" href="#">Midge Itz</a><a class="dropdown-item" href="#">Sal Vidge</a><a class="dropdown-item" href="#">Other</a>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="share-block d-flex align-items-center feather-icon mt-2 mt-md-0">
                            <a href="javascript:void();" data-bs-toggle="offcanvas" @click="share_post()" data-bs-target="#share-btn" aria-controls="share-btn">
                                <i class="ri-share-line"></i>
                                <span class="ms-1">{{ post_shares }} Share</span>
                            </a>
                        </div>
                        </div>
                        <hr>
                        <!--<ul class="post-comments list-inline p-0 m-0"><li class="mb-2"><div class="d-flex"><div class="user-img"><img src="view/socialV/assets/images/user/02.jpg" alt="userimg" class="avatar-35 rounded-circle img-fluid"></div><div class="comment-data-block ms-3"><h6>Monty Carlo</h6><p class="mb-0">Lorem ipsum dolor sit amet</p><div class="d-flex flex-wrap align-items-center comment-activity"><a href="javascript:void();">like</a><a href="javascript:void();">reply</a><a href="javascript:void();">translate</a><span> 5 min </span></div></div></div></li><li><div class="d-flex"><div class="user-img"><img src="view/socialV/assets/images/user/03.jpg" alt="userimg" class="avatar-35 rounded-circle img-fluid"></div><div class="comment-data-block ms-3"><h6>Paul Molive</h6><p class="mb-0">Lorem ipsum dolor sit amet</p><div class="d-flex flex-wrap align-items-center comment-activity"><a href="javascript:void();">like</a><a href="javascript:void();">reply</a><a href="javascript:void();">translate</a><span> 5 min </span></div></div></div></li></ul>';
                        <form class="comment-text d-flex align-items-center mt-3" action="javascript:void(0);"><input type="text" class="form-control rounded" placeholder="Enter Your Comment"><div class="comment-attagement d-flex"><a href="javascript:void();"><i class="ri-link me-3"></i></a><a href="javascript:void();"><i class="ri-user-smile-line me-3"></i></a><a href="javascript:void();"><i class="ri-camera-line me-3"></i></a></div></form>-->
                    </div>
                </div>
            </div>
        </div>                
</template>

<script>
import axios from 'axios'

export default {
    name:'Post',
    data(){
        return{
            org_id:37,
            isSubscribed:false,            
            active_post:'',
            isVisible:false,
            seen_posts:''
        }
    },    
    props: ['post_comments','post_id','profile_link','post_by_avatar','post_by_names','post_date','post_text','post_user_sub', 'post_shares', 'post_link', 'post_likes', 'post_page_id', 'post_image', 'post_by_id', 'post_type'],            
    methods:{
        isSubed(){
            if(this.post_user_sub=='yes'){
                return true
            }
            else{
                return false
            }
        },
        async share_post(){
            var url='post.php?action=share&post_id='+this.post_id
            const response = await axios.get(url,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })
            var data=response.data                                 
            if(data.response_code==200){                     
                //Let display the share icons
                var post_link=data.post_link                
                document.getElementById("sharer_area").innerHTML='<div class="text-center me-3 mb-3"><a href="#fb_sharer" id="fb_sharer" onclick="window.open(\'https://www.facebook.com/sharer/sharer.php?u='+post_link+'\',\'facebook-share-dialog\',\'width=626,height=436\');"><img src="https://tshaku.com/view/socialV/assets/images/icon/08.png" class="img-fluid rounded mb-2" alt=""><h6>Facebook</h6></a></div><div class="text-center me-3 mb-3"><a href="#twitter_sharer" id="twitter_sharer" onclick="window.open(\'http://twitter.com/share?text=&url='+post_link+'\',\'twitter-share-dialog\',\'width=626,height=436\');"><img src="https://tshaku.com/view/socialV/assets/images/icon/09.png" class="img-fluid rounded mb-2" alt=""><h6>Twitter</h6></a></div>';
            }
            else{                 
                //alert(data.response_desc)
            }
        },
        async toogleFollow(){
            if(this.isSubed()){
                //The user is unfollowing
                var url='pages.php?action=unfollow&page_id='+this.post_page_id
                const response = await axios.get(url,{
                    headers: {
                        Authorization:localStorage.getItem('token')
                    }
                })
                var data=response.data                                 
                if(data.response_code==200){                    
                    alert('You have unfollowed this page')
                }
                else{                    
                    alert(data.response_desc)
                } 
                //*/           
                //Send the axios                     
            }
            else{
                //The user is following
                var url='pages.php?action=follow&page_id='+this.post_page_id
                const response = await axios.get(url,{
                    headers: {
                        Authorization:localStorage.getItem('token')
                    }
                })
                var data=response.data                                 
                if(data.response_code==200){                     
                    alert('You are now following this page')
                }
                else{                    
                    alert(data.response_desc)
                }                        
            }
        },        
        async repost(){
            var message='RT|'+this.post_id            
            var form = new FormData()
            form.append('text',message)
            form.append('org_id',this.org_id)            
            form.append('action','add')            
            let conf = {
               header : {
                  'Content-Type' : 'multipart/form-data'
               }
            }                      
            const response = await axios.post('post.php',form,conf)
            //Let do some validations here
            //End let do some validations here
            if(response.status==200){
               //There are no communication issue with the endpoint
               const data=response.data
               if(data.response_code==200){
                  //The request has worked, redirect user to the login page
                  alert('You have reposted this post')
               }
               else{        
                    console.log(data)          
                  alert('Error posting message :'+data.response_desc)
               }
            }
            else{
               alert('An error occured during data submission, please contact support')
            }            
        },
        isElementVisible(element) { 
            const elementTop = element.offsetTop; 
            const elementBottom = elementTop  
                + element.offsetHeight; 
            const viewportTop = window.pageYOffset; 
            const viewportBottom = viewportTop  
                + window.innerHeight; 
  
            return ( 
                elementBottom > viewportTop && 
                elementTop < viewportBottom
            ); 
        }, 
        isValidHttpUrl(string) {
            let url;
            try {
                url = new URL(string);
            } catch (_) {
                return false;
            }
            return url.protocol === "http:" || url.protocol === "https:";
        },
        convert_date(zdate){
            var post_date_ms=new Date(zdate * 1000);                        
            var post_date=post_date_ms.toLocaleDateString("en-US");
            return post_date
        },
        async load_feed_link(link,post_id){
            var seen_posts = this.seen_posts;
            const response = await axios.get('post.php?action=click&post_id='+post_id+'&post_type=link&url='+link+'&seen_posts='+seen_posts,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })        
            var data=response.data         
            //*
            if(data.response_code==200){            
                document.getElementById("post_zone_id_"+post_id).innerHTML='<iframe class="video" id="post_iframe_id'+post_id+'" src="'+data.reponse+'" class="second-row" style="padding:0px;" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" lazyload="" postpone="" sandbox="allow-scripts allow-same-origin">Loading Please wait...</iframe>';
                if(this.active_post){
                    document.getElementById(active_post).innerHTML='<center><img src="images/seen.webp" title="You have seen this,wanna watch again?"/></center>';
                }
                this.active_post="post_zone_id"+post_id
            }
            else{
                alert('invalid url response');
            }            
        },
        async load_wall_video(){
            document.getElementById("post_zone_id_"+this.post_id).scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
            });                
            document.getElementById("post_zone_id_"+this.post_id).innerHTML='<center><img src="https://tshaku.com/view/socialV/assets/images/page-img/page-load-loader.gif" style="width:300px;position:center;"></center>';
            var seen_posts = localStorage.getItem("Seen_posts")               
            var url='post.php?action=watch&url='+this.post_link+'&post_id='+this.post_id+'&post_type=video&seen_posts='+seen_posts                        
            //Empty the localstorage
            localStorage.setItem("Seen_posts",this.seen_posts)         
            //alert(seen_posts)
            ///*
            const response = await axios.get(url,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })        
            var data=response.data            
            if(this.isValidHttpUrl(data)){
                var active_post=document.getElementById("active_post").value;
                if(active_post!=0){
                    //THere is an active post disable it
                    document.getElementById(active_post).innerHTML='<center><img src="https://tshaku.com/images/seen.webp" title="You have seen this,wanna watch again?"/></center>';                                        
                }       
                //document.getElementById("post_zone_id_"+this.post_id).style.setProperty('margin-top', '0px');      
                //document.getElementById("post_zone_id_"+this.post_id).style.setProperty('position', 'fixed');      
                document.getElementById("active_post").value="post_zone_id_"+this.post_id;                
                document.getElementById("post_zone_id_"+this.post_id).innerHTML='<div class="video-wrap"><div class="video-inner"><iframe class="video" id="post_iframe_id'+this.post_id+'" src="'+data+'" class="second-row" style="padding:0px;" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" lazyload="" postpone="" sandbox="allow-scripts allow-same-origin"></iframe></div></div>';                
                document.getElementById("post_zone_id_"+this.post_id).scrollIntoView({
                        behavior: 'auto',
                        block: 'center',
                        inline: 'center'
                });                          
                /*Pip stuff */
                var $window = $(window);
                var $videoWrap = $('.video-wrap');
                var $video = $('.video-inner');
                var videoHeight = $video.outerHeight();
                $window.on('scroll',  function() {
                    var windowScrollTop = $window.scrollTop(); 
                    var windowScrollBottom = $window.scrollTop() + $window.height();
                    var videoBottom = videoHeight + $videoWrap.offset().top;  
                    var videoTop = videoBottom - videoHeight                    
                    if (windowScrollTop > videoBottom) {
                        $videoWrap.height(videoHeight);
                        $video.addClass('stuck');
                    } 
                    ///*
                    else if (windowScrollBottom < videoTop) {                        
                        $videoWrap.height(videoHeight);
                        $video.addClass('stuck');                  
                    } 
                    //*/
                    else {
                        $videoWrap.height('auto');
                        $video.removeClass('stuck');
                    }
                });
                /*Pip stuff */     
                //Remove this post from cached data
                /*
                var cachedData = JSON.parse(localStorage.getItem('Home_posts'));
                if(cachedData){
                    //Remove this post from cache
                    for (var key in cachedData) {
                        var obj=cachedData[key]
                        if(obj.post_id==this.post_id){                            
                            delete cachedData[key]                            
                        }                        
                    }
                    //remove all the above seen posts
                    
                    seen_posts=this.seen_posts;
                    var myarr = seen_posts.split('|');
                    for (var k in myarr){
                        var seen_post_id=myarr[k]
                        if(seen_post_id){                            
                            //Search for this post id's key in the cached Data
                            for (var key2 in cachedData) {
                                var obj=cachedData[key2]
                                if(obj.post_id==seen_post_id){                                    
                                    delete cachedData[key2]
                                }
                            }
                        }
                    }
                                        
                    const newCache=cachedData.filter(value => JSON.stringify(value) !== '{}');                                                          
                    localStorage.setItem('Home_posts', JSON.stringify(newCache));
                    
                } 
                //*/          
            }
            else{
                alert('Invalid url response')
            }            
        },                
    }
}
</script>

<style scoped>
    div{
        text-align:left;
    }    
</style>