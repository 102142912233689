<template>
    <input type="hidden" id="feed_hashtag" value="0"/>
    <input type="hidden" id="feed_status" value="0"/>      
         <div class="container" style="display:none;" id="chat_contain">
            <div class="row">
               <div class="panel panel-chat">
                  <div class="panel-heading">
                        <a href="#" class="chatMinimize" onclick="return false"><span id="chat_box_title">Chat</span></a>
                        <a href="#" class="chatClose" onclick="return false"><i class="ri-close-line"></i></a>
                        <div class="clearFix"></div>
                  </div>
                  <div class="panel-body" id="message-box">                     
                        <div class="clearFix"></div>
                  </div>
                  <div class="panel-footer" style="background:#FFF;">
                     <input type="hidden" name="name" id="name" value="<?php echo $_SESSION['first_name']; ?>" maxlength="15" />
                     <textarea name="message" id="message" cols="0" rows="0"></textarea>
                     <button id="send-message">Send</button>
                  </div>
               </div>
            </div>
         </div>               
    
    <!-- Wrapper End-->
    <footer class="iq-footer bg-white">
       <div class="container-fluid">
          <div class="row">
             <div class="col-lg-6">
                <ul class="list-inline mb-0">
                   <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
                   <li class="list-inline-item"><a href="#">Terms of Use</a></li>
                </ul>
             </div>
             <div class="col-lg-6 d-flex justify-content-end">
                Copyright <a href="#">Tshaku</a> All Rights Reserved.
             </div>
          </div>
       </div>
    </footer>
    <nav class="iq-float-menu">
       <input type="checkbox" class="iq-float-menu-open" name="menu-open" id="menu-open" />
       <label class="iq-float-menu-open-button" for="menu-open">
          <span class="lines line-1"></span>
          <span class="lines line-2"></span>
          <span class="lines line-3"></span>
       </label>
       <button class="iq-float-menu-item bg-danger"  data-toggle="tooltip" data-placement="top" title="Scroll to Active Post" @click="scroll_to_active_post();"><i class="ri-play-circle-line"></i></button>
       <button class="iq-float-menu-item bg-warning" data-toggle="tooltip" data-placement="top" title="Scroll to top" @click="scrollToTop();"><i class="ri-arrow-up-fill"></i></button>
       <button class="iq-float-menu-item bg-info"  data-toggle="tooltip" data-placement="top" title="Direction Mode" data-mode="rtl"><i class="ri-text-direction-r"></i></button>        
    </nav>
    <!-- Backend Bundle JavaScript -->

    <!-- offcanvas start -->
 
    <div class="offcanvas offcanvas-bottom share-offcanvas" tabindex="-1" id="share-btn" aria-labelledby="shareBottomLabel">
       <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="shareBottomLabel">Share</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
       </div>
       <div class="offcanvas-body small">
          <div class="d-flex flex-wrap align-items-center" id="sharer_area">
             <img src="images/loading-small.gif" alt=""/>
          </div>
       </div>
    </div>   
  
</template>

<script>
export default {
   methods:{
      scroll_to_active_post(){         
        var active_post=document.getElementById("active_post").value;
         document.getElementById(active_post).scrollIntoView({behavior: 'auto',block: 'center',inline: 'center'});        
      },
      scrollToTop(){
         window.scrollTo({ top: 0, behavior: 'smooth' });
      }
   }
}
</script>

<style>

</style>