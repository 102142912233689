<template>    
      <div class="iq-sidebar  sidebar-default ">
          <div id="sidebar-scrollbar">
              <nav class="iq-sidebar-menu">
                  <ul id="iq-sidebar-toggle" class="iq-menu">
                      <li class="active">
                          <router-link to="/">
                              <i class="las la-newspaper"></i><span>Newsfeed</span>
                          </router-link>
                      </li>
                      <li class="">
                            <router-link :to="'/profile/'+id">
                             <i class="las la-user"></i><span>Profile</span>
                            </router-link>
                      </li>
                      <li class=" ">
                           <a href="#" class=" ">
                              <i class="lab la-rocketchat"></i><span>Chat</span>
                          </a>
                      </li>         
                      <li class=" ">
                           <router-link :to="'/explorer'">
                              <i class="las la-compass"></i><span>Explore</span>
                           </router-link>
                      </li>                                       
                  </ul>
                  </nav>
                  <div class="p-5"></div>
              </div>
          </div>
      
        <div class="iq-top-navbar">
          <div class="iq-navbar-custom">
              <nav class="navbar navbar-expand-lg navbar-light p-0">
                  <div class="iq-navbar-logo d-flex justify-content-between">
                      <router-link to="/" v-on:click="load_more_posts">
                          <img src="https://tshaku.com/view/socialV/assets/images/logo.png" class="img-fluid" alt="">
                          <span>Tshaku</span>
                      </router-link>
                      <div class="iq-menu-bt align-self-center">
                          <div class="wrapper-menu">
                              <div class="main-circle"><i class="ri-menu-line"></i></div>
                          </div>
                      </div>
                  </div>
                  <div class="iq-search-bar device-search">
                      <form @submit.prevent="search" class="searchbox">
                          <a class="search-link" href="#"><i class="ri-search-line"></i></a>
                          <input type="text" v-model="search_query" class="text search-input" style="color:#fff; -webkit-text-fill-color: rgba(255, 255, 255, 1); -webkit-opacity: 1;" placeholder="Search here...">
                      </form>
                  </div>
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                      aria-label="Toggle navigation">
                      <i class="ri-menu-3-line"></i>
                  </button>
                  <div class="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul class="navbar-nav  ms-auto navbar-list">
                          <li>
                              <router-link to="/" class="d-flex align-items-center">
                                  <i class="ri-home-line"></i>
                              </router-link>
                          </li>
                          <li class="nav-item dropdown">
                            <a href="#" class="dropdown-toggle" id="group-drop" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ri-group-line"></i></a>
                            <div class="sub-drop sub-drop-large dropdown-menu" aria-labelledby="group-drop">
                                <div class="card shadow-none m-0">
                                  <div class="card-header d-flex justify-content-between bg-primary">
                                      <div class="header-title">
                                        <h5 class="mb-0 text-white">Friend Request</h5>
                                      </div>
                                      <small class="badge  bg-light text-dark " id="num_friend_requests">0</small>
                                  </div>
                                  <div class="card-body p-0" id="friend_request_area">
                                    
                                    <div class="text-center">
                                        <a href="#" class=" btn text-primary">View More Request</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                          </li>
                          <li class="nav-item dropdown">
                                <a href="#" class="search-toggle   dropdown-toggle" id="notification-drop" data-bs-toggle="dropdown">
                                    <i class="ri-notification-4-line"></i>
                                </a>
                                <div class="sub-drop dropdown-menu" aria-labelledby="notification-drop">
                                    <div class="card shadow-none m-0">
                                        <div class="card-header d-flex justify-content-between bg-primary">
                                            <div class="header-title bg-primary">
                                                <h5 class="mb-0 text-white">All Notifications</h5>
                                            </div>
                                            <small class="badge  bg-light text-dark" id="num_notifications">0</small>
                                        </div>
                                        <div class="card-body p-0" id="notification_area">
                                            
                                        </div>
                                    </div>
                                </div>
                          </li>
                          <li class="nav-item dropdown">
                                <a href="#" class="dropdown-toggle" id="mail-drop" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="ri-mail-line"></i>
                                </a>
                                <div class="sub-drop dropdown-menu" aria-labelledby="mail-drop">
                                    <div class="card shadow-none m-0">
                                        <div class="card-header d-flex justify-content-between bg-primary">
                                            <div class="header-title bg-primary">
                                                <h5 class="mb-0 text-white">All Message</h5>
                                            </div>
                                            <small class="badge bg-light text-dark" id="num_messages">0</small>
                                        </div>
                                        <div class="card-body p-0 " id="messages_area">
                                        </div>
                                    </div>
                                </div>
                            </li>
                           <li class="nav-item dropdown">
                              <a href="#" class="   d-flex align-items-center dropdown-toggle" id="drop-down-arrow" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <img :src="avatar" class="img-fluid rounded-circle me-3" alt="user">
                                  <div class="caption">
                                      <h6 class="mb-0 line-height">{{first_name}} {{last_name}}</h6>
                                  </div>
                              </a>
                              <div class="sub-drop dropdown-menu caption-menu" aria-labelledby="drop-down-arrow">
                                  <div class="card shadow-none m-0">
                                       <div class="card-header  bg-primary">
                                          <div class="header-title">
                                              <h5 class="mb-0 text-white">Hello {{first_name}} {{last_name}}</h5>
                                              <span class="text-white font-size-12">Available</span>
                                          </div>
                                      </div>
                                      <div class="card-body p-0 ">
                                          <router-link :to="'/profile/'+id" class="iq-sub-card iq-bg-primary-hover">
                                              <div class="d-flex align-items-center">
                                                  <div class="rounded card-icon bg-soft-primary">
                                                      <i class="ri-file-user-line"></i>
                                                  </div>
                                                  <div class="ms-3">
                                                      <h6 class="mb-0 ">My Profile</h6>
                                                      <p class="mb-0 font-size-12">View personal profile details.</p>
                                                  </div>
                                              </div>
                                          </router-link>
                                          <router-link to="/profile/edit" class="iq-sub-card iq-bg-warning-hover">
                                              <div class="d-flex align-items-center">
                                                  <div class="rounded card-icon bg-soft-warning">
                                                      <i class="ri-profile-line"></i>
                                                  </div>
                                                  <div class="ms-3">
                                                      <h6 class="mb-0 ">Edit Profile</h6>
                                                      <p class="mb-0 font-size-12">Modify your personal details.</p>
                                                  </div>
                                              </div>
                                          </router-link>
                                          <a href="#" class="iq-sub-card iq-bg-info-hover">
                                              <div class="d-flex align-items-center">
                                                  <div class="rounded card-icon bg-soft-info">
                                                      <i class="ri-account-box-line"></i>
                                                  </div>
                                                  <div class="ms-3">
                                                      <h6 class="mb-0 ">Account settings</h6>
                                                      <p class="mb-0 font-size-12">Manage your account parameters.</p>
                                                  </div>
                                              </div>
                                          </a>
                                          <a href="#" class="iq-sub-card iq-bg-danger-hover">
                                              <div class="d-flex align-items-center">
                                                  <div class="rounded card-icon bg-soft-danger">
                                                      <i class="ri-lock-line"></i>
                                                  </div>
                                                  <div class="ms-3">
                                                      <h6 class="mb-0 ">Privacy Settings</h6>
                                                      <p class="mb-0 font-size-12">Control your privacy parameters.
                                                      </p>
                                                  </div>
                                              </div>
                                          </a>
                                          <div class="d-inline-block w-100 text-center p-3">
                                              <a class="btn btn-primary iq-sign-btn" @click="logout" role="button">Sign out<i class="ri-login-box-line ms-2"></i></a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li>
                      </ul>               
                  </div>
              </nav>
          </div>
      </div>
      <div class="right-sidebar-mini right-sidebar">
           <div class="right-sidebar-panel p-0">
              <div class="card shadow-none">
                 <div class="card-body p-0">
                    <div class="media-height p-3" data-scrollbar="init">
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-online">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/01.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Anna Sthesia</h6>
                             <p class="mb-0">Just Now</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-online">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/02.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Paul Molive</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-online">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/03.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Anna Mull</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-online">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/04.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Paige Turner</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-online">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/11.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Bob Frapples</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-online">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/02.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Barb Ackue</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-online">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/03.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Greta Life</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-away">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/12.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Ira Membrit</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center mb-4">
                          <div class="iq-profile-avatar status-away">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/01.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Pete Sariya</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                       <div class="d-flex align-items-center">
                          <div class="iq-profile-avatar">
                             <img class="rounded-circle avatar-50" src="https://tshaku.com/view/socialV/assets/images/user/02.jpg" alt="">
                          </div>
                          <div class="ms-3">
                             <h6 class="mb-0">Monty Carlo</h6>
                             <p class="mb-0">Admin</p>
                          </div>
                       </div>
                    </div>
                    <div class="right-sidebar-toggle bg-primary text-white mt-3">
                       <i class="ri-arrow-left-line side-left-icon"></i>
                       <i class="ri-arrow-right-line side-right-icon"><span class="ms-3 d-inline-block">Close Menu</span></i>
                    </div>
                 </div>
              </div>
           </div>
        </div>                
        <!-- CONTENT PAGE START -->  
</template>
<script>
export default {
    name : 'ContentHeader',
    data(){
        return {
            search_query:null
        }
    },
    props: ['first_name','last_name','avatar','id'],
    methods : {
        logout(){            
            localStorage.removeItem('token')
            this.$router.push('/login')
        },
        search(){
            this.$router.push('/search/'+this.search_query)            
        }        
    }
}
</script>

<style>

</style>
    