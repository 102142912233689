<template>  
    <ContentHeader :first_name="first_name" :last_name="last_name" :avatar="avatar" :id="user_id"/>
    <div class="container" v-if="!results_found">
        <div class="row">
            <div class="col-md-12">
                <div class="alert alert-danger">{{results_message}}</div>
            </div>
        </div>
    </div>  
    <div class="container" v-else>
        <div class="row">                          
                <div class="col-md-6" v-for="result in results" :key="result.id">
                    <div class="card card-block card-stretch card-height" v-if="result.names">
                        <div class="card-body profile-page p-0">
                            <div class="profile-header-image">
                                <div class="cover-container">
                                    <img :src="'https://tshaku.com/'+result.page_banner" alt="profile-bg" class="rounded img-fluid w-100">
                                </div>
                                <div class="profile-info p-4">
                                    <div class="user-detail">
                                    <div class="d-flex flex-wrap justify-content-between align-items-start">
                                        <div class="profile-detail d-flex">
                                            <div class="profile-img pe-4">
                                                <img :src="'https://tshaku.com/'+result.avatar" alt="profile-img" class="avatar-130 img-fluid" />
                                            </div>
                                            <div class="user-data-block">
                                                <h4>
                                                    <router-link :to="'/profile/'+result.id">{{ result.names }}</router-link>
                                                </h4>
                                                <h6>{{ result.city }}</h6>
                                                <p>{{ result.country }}</p>
                                            </div>
                                        </div>
                                        <button v-if="result.isFollower" type="submit" class="btn btn-primary" @click="unfollow_page(result,this)">Un-Follow</button>
                                        <button v-else type="submit" class="btn btn-primary" @click="follow_page(result,this)">Follow</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                                   
        </div>
    </div>
    <ContentFooter />
</template>

<script>
import router from '@/router'
import axios from 'axios'
import ContentHeader from './includes/ContentHeader.vue'
import ContentFooter from './includes/ContentFooter.vue'

export default {
    data(){
        return{
            first_name:'',
            last_name:'',            
            avatar:'https://tshaku.com/',
            results:null,
            results_message:'Searching...',            
            results_found:false

        }
    },
    props:['q','user_id','first_name','last_name','avatar'],
    watch: { 
        q: function() { // watch it
            this.results_found=false
            this.results_message='Searching...'  
            this.search_results()          
        }
    },
    components:{ContentHeader,ContentFooter},    
    methods:{
        change_text(btn){
            alert('this')
            btn.innerHTML="false"
        },
        async follow_page(result,btn){
            ///*
            var url='pages.php?action=follow&page_id='+result.page_id
            const response = await axios.get(url,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })
            var data=response.data                                 
            if(data.response_code==200){
                result.isFollower=true;   
                alert('You are now following '+result.names)
            }
            else{
                alert('Unable to follow this user, please try again later')
            } 
            //*/           
            //Send the axios         
        },
        async unfollow_page(result){
            var url='pages.php?action=unfollow&page_id='+result.page_id
            const response = await axios.get(url,{
                headers: {
                    Authorization:localStorage.getItem('token')
                }
            })
            var data=response.data                                 
            if(data.response_code==200){
                result.isFollower=false;
                alert('You have unfollowed '+result.names)
            }
            else{
                alert('Unable to un-follow this user, please try again later')
            }            
        },
        async search_results(){                        
            const response = await axios.get('api.php?action=search_inshuti&client_id=37&q='+this.q,{
            headers: {
                Authorization:localStorage.getItem('token')
            }
            })        
            var data=response.data         
            console.log(data);            
            if(data.response_code==200){
                //There was results found
                this.results_found=true
                this.results=data
                //Show results
            }
            else{
                //There was no results found
                this.results_found=false
                this.results_message='Sorry there are no results for your query'
            }            
        }
    },
    async created(){         
        const response = await axios.get('api.php?action=get_inshuti',{
            headers: {
                Authorization:localStorage.getItem('token')
            }
        })        
        var data=response.data         
        //*
        if(data.response_code==200){            
            //The user is still signed in            
            this.first_name=data.first_name
            this.last_name=data.last_name
            this.avatar+=data.avatar   
            this.search_results()                                        
        }
        else{
            //Access denied remove the cookie and redirect to the login page            
            localStorage.removeItem('token')
            this.$router.push('/login')
        }        
        //*/
    }
    
}
</script>

<style>

</style>